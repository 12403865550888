import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TranscriptionInterfaceComponent} from '../transcription-interface/transcription-interface.component';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';

@Component({
    selector: 'app-transcribe',
    templateUrl: './transcribe.component.html',
    styleUrls: ['./transcribe.component.scss'],
    standalone: false
})
export class TranscribeComponent implements OnInit {

  @Output() transcriptionResult: EventEmitter<string> = new EventEmitter<string>();

  isExtraSmall$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.XSmall)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private matDialog: MatDialog, private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
  }

  openRecorder(): void {
    const dialogRef = this.matDialog.open(TranscriptionInterfaceComponent, {
      panelClass: 'mat-dialog-responsive',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(transcription => {
      if (transcription) {
        this.transcriptionResult.emit(transcription);
      }
    });
  }

}
