import {InjectionToken} from '@angular/core';
import {environment} from '../../../environments/environment';
import Rollbar from 'rollbar';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: environment.rollbarAccessToken,
  captureUncaught: environment.production,
  captureUnhandledRejections: environment.production,
  autoInstrument: { log: environment.production },
  environment: environment.rollbarEnvironment,
  payload: {
    version: environment.version
  }
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}


export const RollbarService = new InjectionToken<Rollbar>('rollbar');
