<mat-dialog-content>
  <div>
    <div class="alert alert-danger m-3" *ngIf="hasError">{{error}}</div>
    <div class="not-supported px-6 py-6" *ngIf="!mediaDevicesSupported">
      <p>Unfortunately this device does not support accessing the microphone, or does not have a hardware microphone, and cannot be used for dictation.</p>
    </div>
    <div class="px-6 py-6" *ngIf="permissionPending && mediaDevicesSupported">
      <h3>Allow Microphone</h3>
      <span>To record and transcribe audio, click "Allow" above to give Xmedical access to your computer's microphone.</span>
    </div>
    <div class="px-6 py-6" *ngIf="permissionDenied && mediaDevicesSupported">
      <h3>Allow Microphone</h3>
      <span>To record and transcribe audio, Xmedical needs access to your microphone. </span>
      <span *ngIf="platform.BLINK">Click <i class="error-icon chrome-mic-error-icon"></i> in the URL bar and choose "Always allow xmedical.app to access your microphone. "</span>
      <span *ngIf="platform.WEBKIT">Click <i class="error-icon chrome-mic-error-icon"></i> in the URL bar and choose "Always allow xmedical.app to access your microphone. "</span>
      <span *ngIf="platform.FIREFOX">Click <i class="error-icon firefox-mic-error-icon"></i> in the URL bar and choose "Always allow xmedical.app to access your microphone. "</span>
      <span *ngIf="platform.EDGE">Click <i class="error-icon edge-mic-error-icon"></i> in the URL bar and choose "Always allow xmedical.app to access your microphone. "</span>
      <span *ngIf="platform.SAFARI">Open your browser's Preferences - Websites and change microphone setting for xmedical.app to "Allow". </span>
      <span>You may need to reload the page for this to take effect</span>
      <button mat-button color="primary" class="mt-3" (click)="reload()">Reload</button>
    </div>
    <div *ngIf="mediaDevicesSupported && permissionGranted">
      <div class="">
        <app-record-interface (textChanged)="onTextChanged($event)"></app-record-interface>
      </div>
      <div class="mt-3">
        <mat-form-field appearance="fill" color="primary" class="w-100" floatLabel="always">
          <mat-label>Transcribed Audio</mat-label>
          <textarea matInput rows="15" [(ngModel)]="transcribedText"></textarea>
        </mat-form-field>
      </div>
      <div class="d-flex flex-row justify-content-end mt-3">
        <button mat-button (click)="dismiss()">Cancel</button>
        <button mat-flat-button color="primary" (click)="insertTranscription()">Insert Transcription</button>
      </div>
    </div>
  </div>
</mat-dialog-content>
