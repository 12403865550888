import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, map, shareReplay} from 'rxjs/operators';
import {TranscribeEvent, TranscribeEventsService} from '../transcribe-events.service';
import {TranscriptionService} from '../transcription.service';
import {MediaAudioChunk} from '../media-recorder.service';
import {RollbarService} from '../../../core/services/rollbar.service';
import Rollbar from 'rollbar';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
@Component({
    selector: 'app-record-interface',
    templateUrl: './record-interface.component.html',
    styleUrls: ['./record-interface.component.scss'],
    standalone: false
})
export class RecordInterfaceComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() textChanged = new EventEmitter<string>();

  constructor(@Inject(RollbarService) private rollbar: Rollbar, private breakpointObserver: BreakpointObserver, private transcribeEventsService: TranscribeEventsService, private transcriptionService: TranscriptionService) {
  }

  private eventsSubscription: Subscription;
  private audioElement: HTMLAudioElement = null;
  private seekerElement: HTMLInputElement = null;
  private raf: number;

  recording = false;
  supported = false;
  error = '';
  duration = 0;
  playbackTime = 0;
  audio: Blob[] = [];
  waveform: number[] = Array(100).fill(2);
  transcribedText = '';
  backupText = '';

  isExtraSmall$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.XSmall)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  @ViewChild('player') player: ElementRef;
  @ViewChild('seeker') seeker: ElementRef;
  @ViewChild('recordInterface') recordInterface: ElementRef;

  get hasError(): boolean {
    return this.error.length > 0;
  }

  get isPlaying(): boolean {
    return !this.player.nativeElement.paused;
  }

  get hasAudio(): boolean {
    return !this.recording && this.audio.length > 0;
  }

  ngOnInit(): void {
    this.eventsSubscription = this.transcribeEventsService.events$.pipe(distinctUntilChanged()).subscribe(event => this.transcriptionEventReceived(event));
    this.startRecording();
  }

  ngAfterViewInit(): void {
    this.audioElement = this.player.nativeElement as HTMLAudioElement;
    this.seekerElement = this.seeker.nativeElement as HTMLInputElement;
  }

  ngOnDestroy(): void {
    this.eventsSubscription?.unsubscribe();
  }

  startRecording() {
    this.transcriptionService.start();
  }

  stopRecording(): void {
    if (!this.recording) { return; }
    this.backupText = this.transcribedText;
    this.transcriptionService.stop();
    this.addAudioToPlayer();
  }

  clearRecording() {
    this.stopRecording();
    this.audio = [];
    this.waveform = Array(100).fill(2);
    this.transcribedText = '';
    this.backupText = '';
    this.audioElement.src = '';
    this.playbackTime = 0;
    this.textChanged.emit(this.transcribedText);
  }

  play() {
    this.seeker.nativeElement.classList.add('animate');
    this.player.nativeElement.play();
    requestAnimationFrame(this.whilePlaying.bind(this));
  }

  pause() {
    this.player.nativeElement.pause();
    cancelAnimationFrame(this.raf);
  }

  onSeekerInput() {
    this.showProgress();
    if (!this.audioElement.paused) {
      cancelAnimationFrame(this.raf);
    }
  }

  onSeekerChange() {
    this.audioElement.currentTime = this.playbackTime;
    if (!this.audioElement.paused) {
      requestAnimationFrame(this.whilePlaying.bind(this));
    }
  }

  private transcriptionEventReceived(event: TranscribeEvent) {
    if (event.type === 'started') {
      this.recording = true;
    }
    if (event.type === 'stopped') {
      this.recording = false;
    }
    if (event.type === 'text_received') {
      this.transcribedText = this.backupText + ' ' + event.data;
      this.textChanged.emit(this.transcribedText);
    }
    if (event.type === 'audio_chunk_received') {
      const chunk = event.data as MediaAudioChunk;
      this.audio.push(chunk.data);
      this.duration += chunk.duration;
      console.log(this.audio.length, this.duration);
    }
    if (event.type === 'audio_level_received') {
      this.waveform.push(event.data);
      this.waveform.shift();
    }
    if (event.type === 'timeout') {
      this.stopRecording();
    }
    if (event.type === 'error') {
      this.error = event.data.error.message;
      this.transcriptionService.stop();
      this.rollbar.error(event.data);
      console.error(event.data);
    }
  }

  private addAudioToPlayer() {
    if (this.audio.length > 0) {
      this.audioElement.src = '';
      this.audioElement.load();
      const audioBlob = new Blob(this.audio);
      this.audioElement.src = URL.createObjectURL(audioBlob);
      this.audioElement.load();
      if (this.audioElement.readyState > 0 ) {
        this.updateDuration();
      }
      this.audioElement.onloadeddata = () => {
        this.updateDuration();
      };
      this.audioElement.onended = () =>  {
        cancelAnimationFrame(this.raf);
      };
    }
  }

  // hack to set correct duration in Chrome
  private updateDuration() {
    if (this.audioElement.duration === Infinity) {
      this.audioElement.currentTime = 1e101;
      this.audioElement.ontimeupdate = () => {
        this.audioElement.ontimeupdate = () => {
          this.duration = isNaN(this.audioElement.duration) ? 0 : this.audioElement.duration * 1000;
          this.playbackTime = this.audioElement.currentTime;
          this.seekerElement.max = Math.floor(this.audioElement.duration).toString();
          return;
        };
        this.audioElement.currentTime = 0;
        this.playbackTime = 0;
      };
    }
  }

  private whilePlaying() {
    this.playbackTime = Math.floor(this.audioElement.currentTime);
    this.showProgress();
    this.raf = requestAnimationFrame(this.whilePlaying.bind(this));
  }

  private showProgress() {
    const seekBeforeWidth = `${this.playbackTime / parseInt(this.seekerElement.max, 10) * 100}%`;
    this.recordInterface.nativeElement.style.setProperty('--seek-before-width', seekBeforeWidth);
  }
}
