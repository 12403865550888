<div class="w-100 record-interface" #recordInterface>
  <div class="row">
    <div class="col-12">
      <div class="mat-bg-warn" *ngIf="hasError">
        {{error}}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <audio #player preload="metadata">
        Your browser does not support the audio tag.
      </audio>
      <div class="wrapper d-flex flex-row justify-content-between align-items-center md-3" [class.d-none]="!(isExtraSmall$ | async)">
        <button mat-icon-button class="ms-3" (click)="play()" *ngIf="!recording && !isPlaying" [disabled]="!hasAudio"><mat-icon>play_arrow</mat-icon></button>
        <button mat-icon-button class="ms-3" (click)="pause()" *ngIf="!recording && isPlaying" [disabled]="!hasAudio"><mat-icon>pause</mat-icon></button>
        <span class="timestamp mx-3" *ngIf="recording">
          {{duration | appMsToSeconds | appTimeDuration}}
        </span>
        <span class="timestamp mx-3" *ngIf="!recording">
          {{playbackTime | appTimeDuration}}
        </span>
        <div class="waveform d-flex flex-row justify-content-between align-items-center flex-grow-1" *ngIf="recording">
          <ng-container *ngFor="let wave of waveform">
            <div class="wave-item" style="height: {{wave}}%;"></div>
          </ng-container>
        </div>
        <input type="range" class="seeker w-100" #seeker max="100" value="0" [class.d-none]="recording" [class.disabled]="!hasAudio" [disabled]="!hasAudio" [(ngModel)]="playbackTime" (input)="onSeekerInput()" (change)="onSeekerChange()"/>
        <span class="timestamp mx-3" *ngIf="!recording">
          {{duration | appMsToSeconds | appTimeDuration}}
        </span>
      </div>
      <div class="wrapper d-flex flex-row justify-content-between align-items-center" [class.d-none]="!(isExtraSmall$ | async)">
        <button mat-icon-button class="mat-bg-warn text-light ms-3" (click)="clearRecording()" *ngIf="!recording" [disabled]="!hasAudio"><mat-icon>delete</mat-icon></button>
        <button mat-icon-button class="ms-3" (click)="stopRecording()" *ngIf="recording"><mat-icon>pause</mat-icon></button>
        <button mat-icon-button class="ms-3" (click)="startRecording()" *ngIf="!recording"><mat-icon>mic</mat-icon></button>
      </div>
      <div class="wrapper d-flex flex-row justify-content-between align-items-center " [class.d-none]="isExtraSmall$ | async">
        <button mat-icon-button class="mat-bg-warn text-light ms-3" (click)="clearRecording()" *ngIf="!recording" [disabled]="!hasAudio"><mat-icon>delete</mat-icon></button>
        <button mat-icon-button class="ms-3" (click)="play()" *ngIf="!recording && !isPlaying" [disabled]="!hasAudio"><mat-icon>play_arrow</mat-icon></button>
        <button mat-icon-button class="ms-3" (click)="pause()" *ngIf="!recording && isPlaying" [disabled]="!hasAudio"><mat-icon>pause</mat-icon></button>
        <span class="timestamp mx-3" *ngIf="recording">
          {{duration | appMsToSeconds | appTimeDuration}}
        </span>
        <span class="timestamp mx-3" *ngIf="!recording">
          {{playbackTime | appTimeDuration}}
        </span>
        <div class="waveform d-flex flex-row justify-content-between align-items-center flex-grow-1" *ngIf="recording">
          <ng-container *ngFor="let wave of waveform">
            <div class="wave-item" style="height: {{wave}}%;"></div>
          </ng-container>
        </div>
        <mat-slider color="primary" [max]="duration/1000" class="w-100" [class.d-none]="recording" [disabled]="!hasAudio" [(ngModel)]="playbackTime" #ngSlider><input matSliderThumb (input)="onSeekerInput()" #ngSliderThumb="matSliderThumb" (change)="onSeekerChange()" />
          <input matSliderThumb>
        </mat-slider>
        <span class="timestamp mx-3" *ngIf="!recording">
          {{duration | appMsToSeconds | appTimeDuration}}
        </span>
        <button mat-icon-button class="ms-3" (click)="stopRecording()" *ngIf="recording"><mat-icon>pause</mat-icon></button>
        <button mat-icon-button class="ms-3" (click)="startRecording()" *ngIf="!recording"><mat-icon>mic</mat-icon></button>
      </div>
    </div>
  </div>
</div>
