import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioUtilsService {

  mediaDevicesSupported: boolean = window.navigator.mediaDevices && !!window.navigator.mediaDevices.getUserMedia;

  getMediaStream(): Promise<MediaStream> {
    return window.navigator.mediaDevices.getUserMedia({ audio: true});
  }
}
