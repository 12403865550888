import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranscribeEventsService {

  private eventSubject: Subject<TranscribeEvent> = new Subject<TranscribeEvent>();
  events$ = this.eventSubject.asObservable();

  constructor() { }

  triggerEvent(type: TranscribeEventType, data: any): void {
    const event = new TranscribeEvent(type, data);
    this.eventSubject.next(event);
  }
}

export class TranscribeEvent {
  constructor(readonly type: TranscribeEventType, readonly data: any = null) {
  }
}
export type TranscribeEventType =
  | 'start'
  | 'started'
  | 'stop'
  | 'stopped'
  | 'timeout'
  | 'audio_chunk_received'
  | 'audio_level_received'
  | 'text_received'
  | 'permission_changed'
  | 'error';
