import {ControlType} from './control-type.enum';
import {WidthType} from './width-type.enum';
import {JumpTextEntity} from '../../report-renderer/jump-text-renderer/jump-text-entity';
import {Medication} from '../../medication-repository/entities/medication';
import {Problem} from '../../problems-repository/entities/problem';
import {TreatmentPlanItem} from '../../treatment-plans-repository/entities/treatment-plan-item';
import {Patient} from '../../patient-repository/entities/patient';

export interface IReportControl {
  type: ControlType;
  width: WidthType;
  label: string;
  placeHolder: string;
  units: string;
  value: string;
  jumpTarget: string;
  inline: boolean;
  isEditing: boolean;
  showTitleDate: boolean;
  titleDateValue: string;
  displayContactDetails: boolean;
  displayAddressDetails: boolean;
  displayMedicalAidDetails: boolean;
  displayPracticeDetails: boolean;
  displayDoctorsDetails: boolean;
  procedureId: number;
  procedureField: number;
  medicationListValue: Medication[];
  problemListValue: Problem[];
  treatmentPlanListValue: TreatmentPlanItem[];
  patientValue: Patient;
  bold: boolean;
  labelColour: string;
  valueColour: string;
}


export class ReportControl implements IReportControl {
    type: ControlType = ControlType.SINGLE_LINE_TEXT_FIELD;
    width: WidthType = WidthType.AUTO;
    label = '';
    placeHolder = '';
    units = '';
    value = '';
    jumpTarget = '#';
    inline = false;
    isEditing = false;
    showTitleDate = false;
    titleDateValue = '';
    displayContactDetails = false;
    displayAddressDetails = false;
    displayMedicalAidDetails = false;
    displayPracticeDetails = false;
    displayDoctorsDetails = false;
    medicationListValue = [];
    problemListValue = [];
    treatmentPlanListValue = [];
    patientValue = null;
    bold = false;
    procedureId: number;
    procedureField: number;
    labelColour = '#000000';
    valueColour = '#000000';

    get jumpTextTemplate(): JumpTextEntity {
      return {
        text: this.value,
        jumpTarget: this.jumpTarget,
        replaceList: ''
      } as JumpTextEntity;
    }

    setWidthTypeFromIndex(index: any) {
        index = parseInt(index, 10);
        switch (index) {
            case 0:
                this.width = WidthType.AUTO;
                break;
            case 1:
                this.width = WidthType.FULL;
                break;
            case 2:
                this.width = WidthType.THREE_QUARTERS;
                break;
            case 3:
                this.width = WidthType.TWO_THIRDS;
                break;
            case 4:
                this.width = WidthType.HALF;
                break;
            case 5:
                this.width = WidthType.ONE_THIRD;
                break;
            case 6:
                this.width = WidthType.ONE_QUARTER;
                break;
            default:
                this.width = WidthType.AUTO;
                break;
        }
    }

    get showDate(): boolean {
        return this.type === ControlType.SECTION_TITLE && this.showTitleDate;
    }

    get hasLabel(): boolean {
        return this.label.length > 0;
    }

    get hasUnits(): boolean {
        return this.units.length > 0;
    }

    get hasPlaceholder(): boolean {
        return this.placeHolder.length > 0;
    }

    get widthChanged(): boolean {
        return this.width !== WidthType.AUTO;
    }

    get isSectionTitle(): boolean {
        return this.type ===  ControlType.SECTION_TITLE;
    }

    get isDatePicker(): boolean {
        return this.type === ControlType.DATE_PICKER;
    }

    get isSingleLineTextField(): boolean {
        return this.type === ControlType.SINGLE_LINE_TEXT_FIELD;
    }

    get isMultiLineTextField(): boolean {
        return this.type === ControlType.MULTILINE_TEXT_FIELD;
    }

    get isJumpTextField(): boolean {
        return this.type === ControlType.JUMP_TEXT_FIELD;
    }

    get isPatientSummary(): boolean {
        return this.type === ControlType.PATIENT_SUMMARY;
    }

    get isLatestComment(): boolean {
        return this.type === ControlType.LATEST_COMMENT;
    }

    get isProblemList(): boolean {
        return this.type === ControlType.PROBLEM_LIST;
    }

    get isMedicationList(): boolean {
        return this.type === ControlType.MEDICATION_LIST;
    }

    get isDiagnosisList(): boolean {
        return this.type === ControlType.DIAGNOSIS_LIST;
    }

    get isTreatmentPlanList(): boolean {
      return this.type === ControlType.TREATMENT_PLAN_LIST;
    }

    get isSignatureField(): boolean {
      return this.type === ControlType.SIGNATURE_FIELD;
    }

    get isProcedureField(): boolean {
      return this.type === ControlType.PROCEDURE_FIELD;
    }

    get widthClass(): string {
        switch (this.width) {
            case WidthType.AUTO:
                return 'col-sm';
            case WidthType.FULL:
                return 'col-sm-12';
            case WidthType.THREE_QUARTERS:
                return 'col-sm-9';
            case WidthType.TWO_THIRDS:
                return 'col-sm-8';
            case WidthType.HALF:
                return 'col-sm-6';
            case WidthType.ONE_THIRD:
                return 'col-sm-4';
            case WidthType.ONE_QUARTER:
                return 'col-sm-3';
            default:
                return 'col-sm-12';
        }
    }

    get title(): string {
        switch (this.type) {
            case ControlType.SECTION_TITLE:
                return 'Section Title';
            case ControlType.DATE_PICKER:
                return 'Date Picker';
            case ControlType.PATIENT_SUMMARY:
                return 'Patient Summary';
            case ControlType.SINGLE_LINE_TEXT_FIELD:
                return 'Text Field';
            case ControlType.MULTILINE_TEXT_FIELD:
                return 'Multiline Text Field';
            case ControlType.JUMP_TEXT_FIELD:
                return 'Jump Text Field';
            case ControlType.LATEST_COMMENT:
                return 'Latest Comment';
            case ControlType.MEDICATION_LIST:
                return 'Medication List';
            case ControlType.PROBLEM_LIST:
                return 'Problem List';
            case ControlType.DIAGNOSIS_LIST:
                return 'Diagnosis List';
          case ControlType.TREATMENT_PLAN_LIST:
            return 'Treatment Plan List';
          case ControlType.PROCEDURE_FIELD:
            return 'Procedure Field';
          case ControlType.SIGNATURE_FIELD:
            return 'Signature Block';
            default:
                return 'Single Line Text Field';
        }
    }
}

export class ReportControlBuilder {

    static initializeNewControl(type: ControlType): ReportControl {
        const control = new ReportControl();
        control.type = type;
        control.label = control.title;
        switch (control.type) {
            case ControlType.PATIENT_SUMMARY:
                control.width = WidthType.FULL;
                control.displayContactDetails = true;
                control.displayAddressDetails = true;
                control.displayMedicalAidDetails = true;
                control.displayPracticeDetails = true;
                control.displayDoctorsDetails = true;
                break;
            case ControlType.SIGNATURE_FIELD:
                control.width = WidthType.FULL;
                control.value = 'Dr Name Doctor';
                break;
            default:
                control.width = WidthType.AUTO;
                break;
        }
        return control;
    }

    static fromJson(jsonObject: IReportControl): ReportControl {
        const control = new ReportControl();
        control.type = jsonObject?.type ?? ControlType.SINGLE_LINE_TEXT_FIELD;
        control.width = jsonObject?.width ?? WidthType.AUTO;
        control.label = jsonObject?.label ?? '';
        control.value = jsonObject?.value ?? '';
        control.units = jsonObject?.units ?? '';
        control.placeHolder = jsonObject?.placeHolder ?? '';
        control.jumpTarget = jsonObject?.jumpTarget ?? '#';
        control.inline = jsonObject?.inline ?? false;
        control.isEditing = jsonObject?.isEditing ?? false;
        control.showTitleDate = jsonObject?.showTitleDate ?? false;
        control.titleDateValue = jsonObject?.titleDateValue ?? '';
        control.displayContactDetails = jsonObject?.displayContactDetails ?? false;
        control.displayAddressDetails = jsonObject?.displayAddressDetails ?? false;
        control.displayMedicalAidDetails = jsonObject?.displayMedicalAidDetails ?? false;
        control.displayPracticeDetails = jsonObject?.displayPracticeDetails ?? false;
        control.displayDoctorsDetails = jsonObject?.displayDoctorsDetails ?? false;
        control.treatmentPlanListValue = jsonObject?.treatmentPlanListValue;
        control.medicationListValue = jsonObject?.medicationListValue;
        control.problemListValue = jsonObject?.problemListValue;
        control.patientValue = jsonObject?.patientValue;
        control.bold = jsonObject?.bold ?? false;
        control.procedureId = jsonObject?.procedureId ?? 0;
        control.procedureField = jsonObject?.procedureField ?? 0;
        control.labelColour = jsonObject?.labelColour ?? '#000000';
        control.valueColour = jsonObject?.valueColour ?? '#000000';
        return control;
    }
}
