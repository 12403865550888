import {Component, OnDestroy, OnInit} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import {TranscriptionService} from '../transcription.service';
import {TranscribeEventsService} from '../transcribe-events.service';
import {Subscription} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-transcription-interface',
    templateUrl: './transcription-interface.component.html',
    styleUrls: ['./transcription-interface.component.scss'],
    standalone: false
})
export class TranscriptionInterfaceComponent implements OnInit, OnDestroy {

  transcribedText = '';
  error = '';
  private eventsSubscription: Subscription;

  constructor(private dialogRef: MatDialogRef<TranscriptionInterfaceComponent>, private transcriptionService: TranscriptionService, public platform: Platform, private eventsService: TranscribeEventsService) { }

  get mediaDevicesSupported(): boolean {
    return this.transcriptionService.mediaDevicesSupported;
  }
  get permissionPending(): boolean {
    return this.transcriptionService.permissionPending;
  }
  get permissionGranted(): boolean {
    return this.transcriptionService.permissionGranted;
  }
  get permissionDenied(): boolean {
    return this.transcriptionService.permissionDenied;
  }
  get hasError(): boolean {
    return this.error.length > 0;
  }

  ngOnInit(): void {
    this.transcriptionService.init();
    this.eventsSubscription = this.eventsService.events$.subscribe(event => {
      if (event.type === 'error') {
        this.error = event.data;
      }
      if (event.type === 'permission_changed') {
        console.log(event.data);
      }
    });
  }

  ngOnDestroy(): void {
    this.eventsSubscription?.unsubscribe();
  }

  onTextChanged(text: string) {
    this.transcribedText = text || '';
  }

  insertTranscription() {
    this.dialogRef.close(this.transcribedText);
  }

  dismiss() {
    this.dialogRef.close();
  }

  reload() {
    window.location.reload();
  }
}
