import {Injectable, OnDestroy} from '@angular/core';
import {MediaRecorderService} from './media-recorder.service';
import {VolumeAnalyserService} from './volume-analyser.service';
import {AudioUtilsService} from './audio-utils.service';
import {TranscribeEventsService} from './transcribe-events.service';
import {AudioPermission} from './audio-permission.enum';
import {WebSpeechApiService} from './web-speech-api.service';

@Injectable({
  providedIn: 'root'
})
export class TranscriptionService implements OnDestroy {

  private timeout: ReturnType<typeof setTimeout>;
  private mediaStream: MediaStream;
  private permissionStatus: AudioPermission = AudioPermission.PENDING;

  constructor(private eventService: TranscribeEventsService,
              private audioUtils: AudioUtilsService,
              private speechApi: WebSpeechApiService,
              private mediaRecorder: MediaRecorderService,
              private audioAnalyserService: VolumeAnalyserService) { }

  get mediaDevicesSupported(): boolean {
    return this.audioUtils.mediaDevicesSupported;
  }

  get permissionPending(): boolean {
    return this.permissionStatus === AudioPermission.PENDING;
  }
  get permissionGranted(): boolean {
    return this.permissionStatus === AudioPermission.GRANTED;
  }
  get permissionDenied(): boolean {
    return this.permissionStatus === AudioPermission.DENIED;
  }

  init() {
    this.permissionStatus = AudioPermission.PENDING;
    this.audioUtils.getMediaStream().then(stream => {
      this.mediaStream = stream;
      this.permissionStatus = AudioPermission.GRANTED;
    }).catch(error => {
      const data = {
        error: error,
        details: 'Could not open media stream',
        service: 'transcription.service'
      };
      this.eventService.triggerEvent('error', data);
      this.permissionStatus = AudioPermission.DENIED;
    });
  }

  start() {
    this.eventService.triggerEvent('start', null);
    if (this.mediaStream !== null) {
      this.mediaRecorder.start(this.mediaStream);
      this.audioAnalyserService.start(this.mediaStream);
      this.speechApi.start();
      this.eventService.triggerEvent('started', null);
    } else {
      this.audioUtils.getMediaStream().then(stream => {
        this.mediaStream = stream;
        this.mediaRecorder.start(stream);
        this.audioAnalyserService.start(stream);
        this.speechApi.start();
        this.eventService.triggerEvent('started', null);
      });
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.eventService.triggerEvent('timeout', null);
      this.stop();
    }, 60000);
  }

  stop() {
    clearTimeout(this.timeout);
    this.eventService.triggerEvent('stop', null);
    this.mediaRecorder.stop();
    this.speechApi.stop();
    this.audioAnalyserService.stop();
    this.mediaStream?.getAudioTracks().forEach(track => track.stop());
    this.mediaStream = null;
    this.eventService.triggerEvent('stopped', null);
  }

  ngOnDestroy(): void {
    this.stop();
  }
}
