import {IReportControl, ReportControl, ReportControlBuilder} from './report-control';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {ControlType} from './control-type.enum';

export interface IReportRow {
  title: string;
  type: ControlType;
  showTitleDate: boolean;
  titleDateValue: string;
  isEditing: boolean;
  labelColour: string;
  controls: IReportControl[];
}

export class ReportRow implements IReportRow {
    title = 'Report Row';
    type: ControlType = ControlType.REPORT_ROW;
    showTitleDate = false;
    titleDateValue = '';
    isEditing = false;
    labelColour = '#000000';
    controls: ReportControl[] = [];

    addControl(index: number, control: ReportControl) {
        if (this.type !== ControlType.REPORT_ROW) { return; }
        this.controls.splice(index, 0, control);
    }

    updateControlIndex(oldIndex: number, newIndex: number) {
        if (this.type !== ControlType.REPORT_ROW) { return; }
        moveItemInArray(this.controls, oldIndex, newIndex);
    }

    removeControl(index: number) {
        if (this.type !== ControlType.REPORT_ROW) { return; }
        this.controls.splice(index, 1);
    }

    get hasControls(): boolean {
        return this.controls.length > 0;
    }

    get showDate(): boolean {
        return (this.type === ControlType.ROW_TITLE || this.type === ControlType.SECTION_TITLE) && this.showTitleDate;
    }

    get isRow(): boolean {
        return this.type ===  ControlType.REPORT_ROW;
    }

    get isRowTitle(): boolean {
        return this.type ===  ControlType.ROW_TITLE;
    }
}

export class ReportRowBuilder {

    static new(type: ControlType): ReportRow {
        switch (type) {
            case ControlType.ROW_TITLE:
                return this.initializeNewRowTitle();
            default:
                return this.initializeNewRow();
        }
    }

    static initializeNewRow(): ReportRow {
        return new ReportRow();
    }

    static initializeNewRowTitle(): ReportRow {
        const row = new ReportRow();
        row.title = 'Row Title';
        row.type = ControlType.ROW_TITLE;
        return row;
    }

    static fromJson(jsonObject: IReportRow): ReportRow {
        const row = new ReportRow();
        row.title = jsonObject?.title ?? '';
        row.type = jsonObject?.type ?? ControlType.REPORT_ROW;
        row.showTitleDate = jsonObject?.showTitleDate ?? false;
        row.titleDateValue = jsonObject?.titleDateValue ?? '';
        row.isEditing = jsonObject?.isEditing ?? false;
        row.labelColour = jsonObject?.labelColour ?? '#000000';
        row.controls = jsonObject.controls.map(c => ReportControlBuilder.fromJson(c));
        return row;
    }
}
