import {Injectable, OnDestroy} from '@angular/core';
import {TranscribeEventsService} from './transcribe-events.service';
import {TranscribeError} from './transcribe-error';

declare var webkitSpeechRecognition: any;
@Injectable({
  providedIn: 'root'
})
export class WebSpeechApiService implements OnDestroy {
  private recognition: any;
  private isStoppedSpeechRecognition = true;

  constructor(private transcribeEventsService: TranscribeEventsService) {
    this.recognition = new webkitSpeechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.recognition.maxAlternatives = 5;
    this.recognition.lang = 'en-US';
    this.recognition.onresult = (event: SpeechRecognitionEvent) => {
      this.transcribeEventsService.triggerEvent('text_received', event.results[0][0].transcript.trim());
    };
    this.recognition.onerror = (error: any) => {
      const data: TranscribeError = {
        error: error,
        details: 'Could not start transcription stream',
        service: 'web-speech-api.service'
      };
      this.transcribeEventsService.triggerEvent('error', data);
    };
    this.recognition.onend = (event: SpeechRecognitionEvent) => {
      if (this.isStoppedSpeechRecognition) {
        this.recognition.stop();
      } else {
        this.recognition.start();
      }
    };
  }

  ngOnDestroy(): void {
    this.stop();
  }

  start() {
    this.isStoppedSpeechRecognition = false;
    this.recognition.start();
  }

  stop() {
    this.isStoppedSpeechRecognition = true;
    this.recognition.stop();
  }

}

export interface SpeechRecognitionEvent {
  resultIndex?: number;
  results?: SpeechRecognitionResultList;
  type: string;
  timeStamp: number;
}
