import {Injectable, OnDestroy} from '@angular/core';
import {interval, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VolumeAnalyserService implements OnDestroy {

  private audioContext: AudioContext;
  private inputSource: MediaStreamAudioSourceNode;
  private analyser: AnalyserNode;
  private dataArray: Uint8Array = new Uint8Array(0);
  private timerSubscription: Subscription;

  constructor() {

  }

  ngOnDestroy() {
    this.stop();
  }

  getAudioData(): Uint8Array {
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    this.analyser.getByteTimeDomainData(this.dataArray);
    return this.dataArray;
  }

  start(stream: MediaStream) {
    this.audioContext = new AudioContext();
    this.inputSource = this.audioContext.createMediaStreamSource(stream);
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 256;
    this.inputSource.connect(this.analyser);
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    this.analyser.getByteTimeDomainData(this.dataArray);
  }

  stop() {
    this.timerSubscription?.unsubscribe();
    if (this.inputSource && this.analyser) {
      this.inputSource.disconnect(this.analyser);
      this.analyser.disconnect();
      this.analyser = null;
      this.inputSource = null;
    }
  }
}

