import {IReportRow, ReportRow, ReportRowBuilder} from './report-row';
import {IPage, Page, ReportPageBuilder} from './page';
import {ReportTag} from '../../report-repository/entities/report-tag';

export interface IReportTemplate {
  id: number;
  title: string;
  description: string;
  page: IPage;
  rows: IReportRow[];
  tags: ReportTag[];
  isEditing: boolean;
  compact: boolean;
  useLetterhead: boolean;
  letterheadId?: number;
  tagString: string;
  procedureId: number;
  procedureEntryId: number;
}

export class ReportTemplate implements IReportTemplate {
    id: number;
    title: string;
    description: string;
    page: Page;
    rows: ReportRow[];
    tags: ReportTag[];
    isEditing: boolean;
    compact: boolean;
    useLetterhead: boolean;
    letterheadId?: number;
    procedureId: number;
    procedureEntryId: number;

    constructor() {
        this.title = 'New Report';
        this.description = '';
        this.page = {
            width: 210,
            height: 297,
            size: 'A4',
            orientation: 'Portrait'
        };
        this.rows = [];
        this.tags = [];
        this.isEditing = false;
        this.compact = false;
        this.useLetterhead = true;
        this.letterheadId = null;
    }

    get tagString(): string {
      return this.tags.map(tag => tag.tag).join(',');
    }

    setPageOrientation(orientation: string) {
      this.page.orientation = orientation;
    }

    setPageSize(width: number, height: number) {
        this.page.width = width;
        this.page.height = height;
        this.page.size = 'CUSTOM';
    }

    setPageSizeByName(name: string) {
        this.page.size = name.toUpperCase();
        switch (name.toUpperCase()) {
            case 'LETTER':
                this.page.width = 215.9;
                this.page.height = 279;
                break;
            case 'A4':
                this.page.width = 210;
                this.page.height = 297;
                break;
            case 'A5':
                this.page.width = 148;
                this.page.height = 210;
                break;
            case 'CUSTOM':
                break;
            default:
                this.page.width = 210;
                this.page.height = 297;
                break;
        }
    }
}

export class ReportBuilder {

    static fromJson(jsonObject: IReportTemplate): ReportTemplate {
        const report = new ReportTemplate();
        report.title = jsonObject?.title ?? '';
        report.description = jsonObject?.description ?? '';
        report.page = ReportPageBuilder.fromJson(jsonObject?.page);
        report.rows = jsonObject?.rows?.map(row => ReportRowBuilder.fromJson(row)) ?? [];
        report.tags = jsonObject?.tags ?? [];
        report.isEditing = jsonObject?.isEditing ?? false;
        report.compact = jsonObject?.compact ?? false;
        report.useLetterhead = jsonObject?.useLetterhead ?? true;
        report.letterheadId = jsonObject?.letterheadId ?? null;
        report.procedureId = jsonObject?.procedureId ?? 0;
        report.procedureEntryId = jsonObject?.procedureEntryId ?? 0;
        return report;
    }
}
