import {Injectable} from '@angular/core';
import {TagRepository} from '../../shared/components/tag/tags-repository.interface';
import {ReportTag} from './entities/report-tag';
import {Observable} from 'rxjs';
import {ReportTagCollectionResponse} from './entities/report-tag-collection-response';
import {map} from 'rxjs/operators';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {Report} from './entities/report';
import {ReportItemResponse} from './entities/report-item-response';
import {ReportCollectionResponse} from './entities/report-collection-response';
import {ImportedReportCollectionResponse} from './entities/imported-report-collection-response';
import {ImportedReport} from './entities/imported-report';
import {NewReport} from './entities/new-report';
import {ApiService} from '../base/repository/api.service';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';

@Injectable({
  providedIn: 'root'
})
export class ReportRepositoryService implements TagRepository<ReportTag> {

  constructor(private api: ApiService) {
  }

  createReport(report: NewReport): Observable<number> {
    return this.api.post<ItemCreatedResponse>('reports', report)
      .pipe(map(response => response.value));
  }

  duplicateReport(id: number): Observable<number> {
    return this.api.post<ItemCreatedResponse>('reports/duplicate/' + id)
      .pipe(map(response => response.value));
  }

  updateReport(report: Report): Observable<boolean> {
    return this.api.put<ItemUpdatedResponse>('reports/' + report.id, report)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  loadPatientReports(patientId: number): Observable<Report[]> {
    return this.api.get<ReportCollectionResponse>('reports/patients/' + patientId)
      .pipe(map(response => response.items));
  }

  loadPatientImportedReports(patientId: number): Observable<ImportedReport[]> {
    return this.api.get<ImportedReportCollectionResponse>('reports/imported/patients/' + patientId)
      .pipe(map(response => response.items));
  }

  getReport(id: number): Observable<Report> {
    return this.api.get<ReportItemResponse>('reports/' + id)
      .pipe(map(response => response.value));
  }

  tagLoad(): Observable<ReportTag[]> {
    return this.api.get<ReportTagCollectionResponse>('reports/tags')
      .pipe(map(response => response.items));

  }

  tagSearch(query: string): Observable<ReportTag[]> {
    return this.api.get<ReportTagCollectionResponse>('reports/tags/search', {query: query})
      .pipe(map(response => response.items));

  }


}
