export enum ControlType {
    REPORT_ROW,
    ROW_TITLE,
    SECTION_TITLE,
    DATE_PICKER,
    PATIENT_SUMMARY,
    SINGLE_LINE_TEXT_FIELD,
    MULTILINE_TEXT_FIELD,
    JUMP_TEXT_FIELD,
    LATEST_COMMENT,
    MEDICATION_LIST,
    PROBLEM_LIST,
    DIAGNOSIS_LIST,
    TREATMENT_PLAN_LIST,
    SIGNATURE_FIELD,
    PROCEDURE_FIELD
}
