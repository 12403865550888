export interface IPage {
  width: number;
  height: number;
  size: string;
  orientation: string;
}

export class Page implements IPage {
  width: number;
  height: number;
  size: string;
  orientation: string;
}


export class ReportPageBuilder {
  static fromJson(jsonObject: IPage): Page {
    const page = new Page();
    page.height = jsonObject?.height ?? 297;
    page.width = jsonObject?.width ?? 210;
    page.size = jsonObject?.size ?? 'A4';
    page.orientation = jsonObject?.orientation ?? 'Portrait';
    return page;
  }
}
