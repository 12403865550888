import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RecordInterfaceComponent} from './record-interface/record-interface.component';
import {TranscribeComponent} from './transcribe/transcribe.component';
import {TranscriptionInterfaceComponent} from './transcription-interface/transcription-interface.component';
import {NgCdkModule} from '../../ng-cdk.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSliderModule} from '@angular/material/slider';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
  declarations: [
    RecordInterfaceComponent,
    TranscribeComponent,
    TranscriptionInterfaceComponent
  ],
  imports: [
    CommonModule,
    NgCdkModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    MatSliderModule,
    MatInputModule,
    MatDialogModule
  ],
  exports: [
    TranscribeComponent
  ],
})
export class TranscribeModule {
}
