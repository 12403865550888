import {Injectable, OnDestroy} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MediaRecorderService implements OnDestroy {

  private recorder: MediaRecorder;
  private timestamp = 0;
  private onAudioAvailableCallbacks: MediaAudioCallback[] = [];

  ngOnDestroy() {
    this.stop();
  }

  start(stream: MediaStream) {
    this.recorder = new MediaRecorder(stream);
    this.recorder.ondataavailable = (event: BlobEvent) => this.audioAvailable(event);
    this.recorder.start(500);
    this.timestamp = Date.now();
  }

  stop() {
    if (!this.recorder) { return; }
    this.recorder.stop();
    this.recorder = null;
  }

  onMediaAudioAvailable(callback: MediaAudioCallback) {
    this.onAudioAvailableCallbacks.push(callback);
  }

  private audioAvailable(audioEvent: BlobEvent) {
    const now =  Date.now();
    const duration = now - this.timestamp;
    const chunk = new MediaAudioChunk(audioEvent.data, duration);
    this.timestamp = now;
    this.onAudioAvailableCallbacks.forEach(callback => callback(chunk));
  }
}

type MediaAudioCallback = (chunk: MediaAudioChunk) => void;

export class MediaAudioChunk {
  constructor(readonly data: Blob, readonly duration: number) {
  }
}


